import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import StaticPageComponent from '@fuww/library/src/StaticPage';
import HomeLink from '../components/HomeLink';
import messages from '../lib/messages.mjs';
import Head from '../components/Head';
import Markdown from '../components/Markdown';
import markdown
  from '../data/staticPages/landing/international-fashion-jobs.md';
import Layout from '../components/Layout';
import Hero from '../components/Hero';

const headerImageUrls = {
  mobile: [
    'https://r.fashionunited.com/yyIsZX1y8PitCIkaTuMQu4XF1Hn67VJyZLSgH1OVo2o/resize:fill:42:34:0/gravity:ce/quality:10/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9tZWRpYS9pbWFnZXMvaW50ZXJuYXRpb25hbF9mYXNoaW9uX2pvYnNfYmFubmVyX21vYmlsZS5qcGc',
    'https://r.fashionunited.com/CGlVEyGXDcw3ZGXlhv_PWGk1QXd-Lo4XjZ8ObnbouLs/resize:fill:360:288:0/gravity:ce/quality:70/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9tZWRpYS9pbWFnZXMvaW50ZXJuYXRpb25hbF9mYXNoaW9uX2pvYnNfYmFubmVyX21vYmlsZS5qcGc',
    'https://r.fashionunited.com/vgoXfJs7GFIDh91j-UyMcgxPeGQUejh5nKpOYaq5HqM/resize:fill:720:576:0/gravity:ce/quality:70/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9tZWRpYS9pbWFnZXMvaW50ZXJuYXRpb25hbF9mYXNoaW9uX2pvYnNfYmFubmVyX21vYmlsZS5qcGc',
  ],
  desktop: [
    'https://r.fashionunited.com/zcX1eDWCx7XA7coGAcLAej2ITSxCYC9BLL5HATrgK7E/resize:fill:42:8:0/gravity:ce/quality:10/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9tZWRpYS9pbWFnZXMvaW50ZXJuYXRpb25hbF9mYXNoaW9uX2pvYnNfYmFubmVyX2Rlc2t0b3AuanBn',
    'https://r.fashionunited.com/taS9JASB6WG_rxQaax840qSHi_gGjUKV4QIrZVhXQRk/resize:fill:1350:277:0/gravity:ce/quality:70/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9tZWRpYS9pbWFnZXMvaW50ZXJuYXRpb25hbF9mYXNoaW9uX2pvYnNfYmFubmVyX2Rlc2t0b3AuanBn',
    'https://r.fashionunited.com/x-e1RoDn1qfZbhJFOPSlxZTjuqF9O684TIWBCX8lugE/resize:fill:2560:526:0/gravity:ce/quality:70/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9tZWRpYS9pbWFnZXMvaW50ZXJuYXRpb25hbF9mYXNoaW9uX2pvYnNfYmFubmVyX2Rlc2t0b3AuanBn',
    'https://r.fashionunited.com/_fTTzWF4NZX82lZLRHc_HF0eWVaL0U2i7tv-aitctaU/resize:fill:5120:1052:0/gravity:ce/quality:70/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9tZWRpYS9pbWFnZXMvaW50ZXJuYXRpb25hbF9mYXNoaW9uX2pvYnNfYmFubmVyX2Rlc2t0b3AuanBn',
  ],
};

const InternationalFashionJobsPage = () => {
  const intl = useIntl();

  const breadcrumbs = [
    <HomeLink />,
    'International fashion jobs',
  ];

  return (
    <>
      <Head
        title={intl.formatMessage(messages['internationalFashionJobs.title'])}
        description={intl.formatMessage(
          messages['internationalFashionJobs.description'],
        )}
      />
      <StaticPageComponent
        breadcrumbs={breadcrumbs}
        content={<Markdown markdown={markdown} allowGfm allowHtml />}
        title={intl.formatMessage(messages['internationalFashionJobs.title'])}
      />
    </>
  );
};

InternationalFashionJobsPage.getLayout = (page: ReactElement) => (
  <Layout
    header={(
      <Hero
        alt="Content marketing banner image"
        imageUrls={headerImageUrls}
      />
    )}
  >
    {page}
  </Layout>
);

export default InternationalFashionJobsPage;
