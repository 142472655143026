import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { PluggableList } from 'react-markdown/lib/react-markdown';

interface MarkdownProperties {
  markdown: string;
  allowHtml?: boolean;
  allowGfm?: boolean;
}

const Markdown = ({ markdown, allowHtml, allowGfm }: MarkdownProperties) => {
  const rehypePlugins = [
    rehypeSlug,
    ...(allowHtml ? [rehypeRaw] : []),
  ];
  const remarkPlugins = [
    ...(allowGfm ? [remarkGfm] : []),
  ];

  return (
    <ReactMarkdown
      remarkPlugins={remarkPlugins}
      rehypePlugins={rehypePlugins as PluggableList}
    >
      {markdown}
    </ReactMarkdown>
  );
};

export default Markdown;
